.title {
  line-height: 72px;
  font-size: 28px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-align: center;
}

.formTitle {
  line-height: 20px;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-align: center;
  margin-top: 10px;
}

.authContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 720px;
}

.authForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 0 8px;
  width: 300px;
  background-color: azure;
}

.authInput {
  margin-top: 20px;
}

.button {
  width: 100%;
  background-color: green;
  margin: 10px 0;
  border: 1px solid black;
  border-radius: 4px;

  color: white;
  text-align: center;
}

.signUp{
  margin: 10px 0;
  color: blue;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
