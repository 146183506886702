@import "index";

.layout {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $black-70;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  padding: 0 20px;
}

.searchLayout{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  background-color: $widget-bg-info;
  padding: 10px;
  label {
    margin-right: 20px;
  }
}

.markerTypeSearch{
  margin-left: 20px;
}

.header {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
  @include t2;
  text-align: center;
}

.icon {
  position: absolute;
  top: 10px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
}

.modal {
  background: $widget-bg-info;
  border: 1px solid $border;
  padding: 0 10px;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: center;

  button {
    width: 208px;
    margin: 0 20px;
  }
}

.actionButtonsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  justify-items: left;
}

.button {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid black;
  padding: 8px 16px;
  text-align: center;
  margin: 0 0 20px 20px;

  &:hover {
    background-color: $white-hover;
  }
  &:disabled {
    background-color: $border;
  }
}
