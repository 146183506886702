@import "index";
@import "node_modules/breakpoint-sass/stylesheets/_breakpoint.scss";

@mixin button-style {
  text-align: center;
  background-color: $primary;
  color: $white;
  margin: 10px 0;
  padding: 4px 8px;
  border-radius: 2px;

  &:hover {
    background-color: $primary-hover;
  }

  &.upload {
    cursor: pointer;
    width: 70px;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.formLayout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 20px;

  @include breakpoint($sm) {
    flex-direction: column;
  }
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.button {
  @include p2;
  @include button-style;

  &_upload {
    @include button-style;
    @include p2;
  }
}

.errors {
  color: $error;
}

.selectControlLayout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.selectControlName {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.closeButton {
  margin-bottom: auto;
}

.image {
  max-height: 300px;
  max-width: 434px;
}
