.notifications {
  margin-right: 40px;
  margin-bottom: 40px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 450px;
  overflow: hidden;
  padding: 5px;
  position: fixed;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 102;
}

.notification {
  align-items: center;
  background-color: #f7fbfb;
  display: flex;
  margin: 5px;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;
  box-shadow: 0 1px 6px rgb(0 0 0 / 16%);

  &_closing {
    animation: notification-closing 0.3s ease-out;
  }

  &_error {
    background-color: #fff6f6;
  }

  &_warning {
    background-color: #fefdf6;
  }

  &_default {
    background-color: #f8fcfe;
  }
}

.content {
  max-width: 376px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;

  padding: 20px 20px 20px 14px;
  width: 100%;
}

@keyframes notification-showing {

  0% {
    opacity: 0;
    margin-left: 100%;
  }
}

@keyframes notification-closing {

  100% {
    opacity: 0;
    margin-left: 100%;
  }
}

.image {
  margin-left: 20px;
}
