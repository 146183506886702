@import "index";

.button {
  background-color: white;
  border-radius: 4px;
  padding: 8px 16px;
  text-align: left;

  &:hover {
    background-color: $white-hover;
  }
}

.imageButton {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid black;
  text-align: center;

  &:hover {
    background-color: $white-hover;
  }
}

.selected {
  background-color: $primary;

  &:hover {
    background-color: $primary-hover;
  }
}

.image {
  width: 29px;
  height: 29px;
}

.buttonsLayout {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 30px;
}

.mainMenuLayout {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
}

.mainMenu {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: $white;

  &_opened {
    width: 200px;
  }

  &_closed {
    width: 0;
  }
}

.collapseOpen {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-bottom: auto;
}

.calendar {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.coordinatesInput {
  position: absolute;
  bottom: 80px;
  right: 20px;
}

.mainImage {
  cursor: pointer;
  display: flex;
  justify-content: center;
  img {
    z-index: 2;
    width: 100px;
    height: 100px;
  }
}
.shopIframe {
  position: absolute;
  bottom: 200px;
  right: 40px;
}

.refreshButton {
    position: absolute;
    top: 90px;
    right: 10px;
}
