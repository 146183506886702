@import "index";

.cardLayout {
  margin-bottom: 5px;
  cursor: pointer;
}
.cardContentLayout {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.text {
  @include p1;
  display: flex;
  align-items: flex-start;
}
.date {
  @include p3;
  display: flex;
  align-items: center;
}

.body {
  background-color: $white;
  &:hover {
    background-color: $white_hover;
  }
}

.image{
  align-self: center;
  margin-right: 10px;
}
