@import "index";

.cardLayout {
  margin-bottom: 5px;
  cursor: pointer;
}
.cardContentLayout {
  display: flex;
  flex-direction: row;
}

.text {
  @include p1;
  display: flex;
  align-items: center;
}

.body {
  background-color: $white;
  &:hover {
    background-color: $white_hover;
  }
}
