@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  src: local(''), url('/public/fonts/OpenSans-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/public/fonts/OpenSans-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  src: local(''), url('/public/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/public/fonts/OpenSans-Bold.ttf') format('truetype');
  font-display: swap;
}
