@import "index";

.radiantPlayer{
  width: 900px;
  height: auto;
  @include breakpoint($sm) {
    width: 450px;
    height: 355px;
  }
}

.popupLayout {
  display: flex;
  flex-direction: column;

  &_desc {
    display: flex;
    flex-direction: row;
  }

  &_unit {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0;
  }
}

.wanted {

}

.contentLayout {
  display: flex;
  flex-direction: row;
}

.imageLayout {
  max-height: 150px;
  position: relative;

  img {
    max-height: 150px;
  }
}

.imageBadge {
  position: absolute;
  bottom: 8px;
  right: 8px;
  border-radius: 8px;
  background-color: $white-60;
  padding: 4px 6px;
}

.content {
  min-width: 150px;
  margin-left: 20px;

  &_title {
    @include t1;
    font-weight: bold;
  }

  &_desc {
    padding-right: 10px;
  }

  &_unit {
    padding-right: 10px;
  }
}

.title {
  @include h5;
  white-space: nowrap;
  margin-bottom: 8px;
}

.desc {
  margin: 10px 0;
  @include t4;
  max-width: 660px;
  white-space: pre-wrap;

  &_file {
    @include p3;
    max-width: 660px;
    white-space: pre-wrap;
    margin-top: 10px;
  }

  &_rumble {
    @include p3;
    white-space: pre-wrap;
    margin-top: 10px;
  }
}

.controlsLayout {
  display: flex;
  flex-direction: row;
  justify-content: left;

  .button {
    @include t4;
    margin: 8px 8px 8px 0;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $primary-hover;
    }
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  background: red;

  &_desc {
    position: initial;
    margin-bottom: auto;
    margin-left: 10px;
  }

  &_unit {
    margin-bottom: auto;
  }
}

.bigPictureInfoLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
}

.bigPictureInfoTitle {
  @include h1;
}

.bigPictureInfoContentLayout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.bigPictureInfoContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid $border;
  border-radius: 8px;
  max-width: 250px;
  padding: 10px;
}

.bigPictureInfoContentTitle {
  @include h4;
}

.bigPictureInfoContentDetails {
  @include t3;
}

.telegramContainer {
  &_unit {

  }

  &_event {
    width: 540px;
  }
}

.tgme_widget_message_photo {
  background-size: auto !important;
}

.responsiveIframe {
  width: 100%;
  border: none;
  @include breakpoint($sm) {
    display: none;
  }
}

.telegramEmbed {
  max-height: 55vh;
  overflow-y: scroll;
}
