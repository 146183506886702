.title {
  line-height: 72px;
  font-size: 28px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  .instruction {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;

    h2 {
      color: #333;
      margin-bottom: 16px;
    }

    p,
    a,
    li {
      color: #666;
      line-height: 1.6;
    }

    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ol {
      text-align: left;
      margin-left: 20px;
    }

    strong {
      color: #d9534f;
    }
  }
}

.formTitle {
  line-height: 20px;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-align: center;
  margin-top: 10px;
}

.authContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.authForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px;
  width: 300px;
  background-color: azure;
}

.authInput {
  margin-top: 20px;
}

.inputLayout{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}
