@import "index";

.button {
  cursor: pointer;
  background: $white;
  border-radius: 4px;
  width: 29px;
  height: 29px;
  margin: 1px 0 1px 10px;
  &:hover {
    background: $white-hover;
  }
}

.active {
  background: $primary;
  &:hover {
    background: $primary-hover;
  }
}

.text {
  margin-left: 10px;
  &_active {
    color: $white;
  }
}

