.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px;
}

.button {
  text-align: center;
  background-color: brown;
  margin: 10px 0;
  color: white;
}

.item{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
