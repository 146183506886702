@mixin h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: 0.03em;
  text-align: left;
}

@mixin h1Mobile {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
}

@mixin h15 {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.03em;
  text-align: left;
}

@mixin h15Mobile {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
}

@mixin h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
}

@mixin h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.03em;
  text-align: left;
}

@mixin h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.03em;
}

@mixin h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

@mixin pCitation {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
}

@mixin p2Citation {
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
}

@mixin h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.03em;
}

@mixin h7 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}

@mixin p1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0;
  text-align: left;
}

@mixin p2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0;
  text-align: left;
}

@mixin p3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

@mixin t1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
}

@mixin t2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

@mixin t3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: left;
}

@mixin t4 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
}

@mixin t5 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}

@mixin t6 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
}

@mixin t7 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
}

@mixin number {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0;
  text-align: left;
}

@mixin b1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
}

@mixin b2 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin b3 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin b4 {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
}

@mixin message {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0;
  text-align: center;
}

@mixin messageMob {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
}

@mixin italic {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
}

@mixin menuHeader {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

@mixin pinData {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

@mixin contentH {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}
