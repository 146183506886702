@import "index";

.markerTitle {
  position: absolute;
  bottom: 54px;
  white-space: nowrap;
  padding: 4px 8px;
  background-color: $white;
  border-radius: 4px;
}

.markerWrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;

  svg {
    position: absolute;
    align-self: center;
  }
}

.iconImageWrapper {
  position: absolute;
  top: 6px;
  overflow: hidden;
  height: 38px;
  border-radius: 50px;
  max-width: 38px;

  img {
    position: relative;
    width: 38px;
  }
}

.marker {
  background-color: #4CAF50;
  border: 1px solid $button-vk-bg-default;
  color: $white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 50%;
}

.images {
  &_special {
    margin-top: auto;
    margin-bottom: auto;
    height: 48px;
  }

  &_country {
    height: 44px;
    position: absolute;
    top: 3px;
  }

  &_soldier {
    z-index: 2
  }

  &_tankWrapper {
    align-items: center;
    display: flex;
    width: 48px;
    height: 48px;
  }

  &_tank {
    position: absolute;
  }
  &_airplane{
    z-index: 2
  }

  &_globalHawk {
    z-index: 2
  }

  &_helicopterWrapper {
    align-items: center;
    display: flex;
    width: 64px;
  }

  &_airPlaneWrapper {
    align-items: center;
    display: flex;
    height: 48px;
    width: 48px;
  }

  &_shipWrapper {
    svg {
      fill: $social-media;
      top: 3px;
      left: 2px;
    }
  }

  &_navyDroneWrapper {
    svg {
      fill: $error;
    }
  }

  &_helicopter {
    position: absolute;
  }

  &_drones {
    height: 48px;
  }

}

.bigImages {
  height: 200px;
}

.unit {
  position: absolute;

  svg {
    height: 48px;
    width: 48px;
  }
}

.image {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border-radius: 50px;
}

.image_hover {
  &:hover {
    background-color: #ffc600;
  }
}
