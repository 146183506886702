@import "index";

.table {
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tableBody {
  flex: 1;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tableHeaderCell {
  &_checkBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.pointer {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $primary-hover;
  }
}
