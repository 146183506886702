.sideBarLayout {
  position: absolute;
  top: 155px;
  right:0;
}

.dataScrollerContainer {
  height: 80vh;
  overflow-y: scroll;
}
