@import "index";
@import "node_modules/breakpoint-sass/stylesheets/_breakpoint.scss";

.layout {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.7);

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.header {
  position: relative;
  padding-top: 10px;
}

.title {
  @include t2;
  text-align: center;
}

.icon {
  z-index: 2;
  position: absolute;
  top: 10px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}

.modal {
  background: $widget-bg-info;
  overflow-y: auto;

  @include breakpoint($sm) {
    height: 95%;
  }
}

.footer {
  display: flex;
  justify-content: center;

  button {
    width: 208px;
    margin: 0 20px;
  }
}
