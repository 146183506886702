$main-font-family: 'Open Sans', -apple-system, blinkmacsystemfont, segoe ui, roboto, oxygen,
  ubuntu, cantarell, fira sans, droid sans, helvetica neue, sans-serif;

// COLORS

$white: #fff;
$white-hover: #fafafa;
$border: #e5e5e5;
$black: #000;
$border-inner: #c1da6c;
$live: #0a9b9b;
$live-hover: #058181;
$primary: #058181;
$primary-hover: #046161;
$primary-disabled: #a3aaaa;
$success-notification: #f7fbfb;
$tertiary: #f3df76;
$tertiary-hover: #f0d64c;
$warning-notification: #fefdf6;
$pushka-pink: #ff0090;
$button-bg-default: #c1da6c;
$button-bg-hover: #95b037;
$button-bg-disabled: #b5b9ac;
$button-text-action-default: #000;
$button-text-price-default: rgb(0 0 0 / 60%);
$button-icon-default: rgb(0 0 0 / 60%);
$button-divider-default: rgb(0 0 0 / 30%);
$button-label-bg: rgb(255 255 255 / 10%);
$button-label-icon: #fff;
$button-vk-bg-default: #07f;
$button-vk-bg-hover: #0060cd;
$secondary: #95b037;
$secondary-hover: #8aa334;
$color-label-bg: rgba($primary, 0.7);
$color-card-bg-default: #058181;
$color-card-bg-hover: #046161;
$color-card-text-main: #fff;
$color-card-text-secondary: rgb(255 255 255 / 60%);
$widget-bg-place: #f2f2f2;
$widget-bg-info: #f2f9f9;
$social-media: #6fbbe9;
$default-notification: #f8fcfe;
$error: #f84a4a;
$error-border: #f84a4a;
$error-notification: #fff6f6;
$footer-background: #fff;
$footer-text-main: #000;
$footer-text-secondary: rgb(0 0 0 / 60%);
$footer-link-text-default: #000;
$footer-link-text-hover: #058181;
$footer-border: #e5e5e5;
$input-border-focus: #c1da6c;
$header-block-bg: #058181;
$header-button-search-bg-default: #95b037;
$header-button-search-bg-hover: #8aa334;
$header-button-search-icon: #fff;
$header-button-profile-bg-default: #f3df76;
$header-button-profile-bg-hover: #f0d64c;
$header-button-profile-icon: #000;

// HEIGHT CONSTANTS
$event-pay-button-height: 70px;
$event-video-xl: 500px;
$event-video-md: 335px;

// BREAKPOINTS:
// XL = desktop, MD = tablet, SM = mobile
$xl: 1200px;
$md: 1199px 768px;
$sm: 767px 0;
$md-and-sm: 1199px 0;
$xl-and-md: 768px;

// WIDTH CONSTANTS
$event-pay-button-width: 335px;
$event-width-wrapper: 1000px;
$event-width-content: 800px;
$max-width-image: 600px;

// OTHER CONSTANTS
$modal-z-index: 101;
$content-bold-weight: 700;
$column-gap-sm: 20px;

@mixin gap($space) {

  &:not(:last-child) {
    margin-bottom: $space;
  }
}

@mixin scrollbar() {

  &::-webkit-scrollbar-track {
    background-color: $black-5;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 0;
  }

  scrollbar-color: $primary $black-5;
  scrollbar-width: 10px;
}
